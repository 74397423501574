interface ImageCreditProps {
  name: string;
}

const ImageCredit = function({name} : ImageCreditProps) {
    return (
      <div className='absolute bottom-0 right-0 z-[2000] p-2'>
        <p className='text-white text-[1vw]'>{name}</p>
      </div>
    );
  }

export default ImageCredit;