import { CreditsType, CreditedPerson } from "../../types/CreditsType";

const articleCredits: CreditedPerson[] = [
    {
        name: "Sophie Esther Ramsey",
        position: "Handbook Editor-in-Chief"

    },
    {
        name: "Eleanor Yuneun Park",
        position: "Varsity Editor-in-Chief",
        showPosition: true
    },
    {
        name: "Divine Angubua",
        position: "Arts & Culture Editor"
    },
    {
        name: "Rubin Beshi",
        position: "Business & Labour Editor"
    },
    {
        name: "Charmaine Yu",
        position: "Opinion Editor"
    },
    {
        name: "Medha Surajpal",
        position: "Science Editor"
    },
    {
        name: "Selia Sanchez",
        position: "News Editor"
    },
    {
        name: "Olga (Olya) Fedossenko",
        position: "Assistant News Editor"
    },
    {
        name: "Isabella Reny",
        position: "Deputy Senior Copy Editor"
    },
    {
        name: "Ozair Chaudhry",
        position: "Senior Copy Editor"
    },
    {
        name: "Jake Takeuchi",
        position: "Sports Editor"
    },
    {
        name: "Ajeetha Vithiyananthan",
        position: "Managing Editor, Internal"
    },
    {
        name: "Kyla Cassandra Cortez",
        position: "Managing Editor, External"
    },
    {
        name: "Maeve Ellis",
        position: "Managing Editor, Online"
    },
    {
        name: "Simon Bettle",
        position: "Contributor"
    },
    {
        name: "Jevan Konyar",
        position: "Contributor"
    },
    {
        name: "Mari Khan",
        position: "Contributor"
    },
    {
        name: "Sanjuktha Vimal",
        position: "Contributor"
    },
    {
        name: "Zulal Tunc",
        position: "Contributor"
    },
    {
        name: "Ella Hamilton",
        position: "Contributor"
    },
    {
        name: "Druphadi Sen",
        position: "Contributor"
    },
    {
        name: "Sofia Tarnopolksy",
        position: "Contributor"
    },
    {
        name: "Tarushi Sahni",
        position: "Contributor"
    },
    {
        name: "Mehar Singh",
        position: "Contributor"
    },
    {
        name: "Bruno Macia",
        position: "Contributor"
    },
    {
        name: "Alyssa Ukani",
        position: "Contributor"
    },
    {
        name: "Aryan Chablani",
        position: "Copy Editor"
    },
    {
        name: "Brooke Schilling",
        position: "Copy Editor"
    },
    {
        name: "Bruno Macia",
        position: "Copy Editor"
    },
    {
        name: "Constantine Vrachas Matthaios",
        position: "Copy Editor"
    },
    {
        name: "Funmi Olaitan",
        position: "Copy Editor"
    },
    {
        name: "Jovana Radin",
        position: "Copy Editor"
    },
    {
        name: "Maram Qarmout",
        position: "Copy Editor"
    },
    {
        name: "Madison Truong",
        position: "Copy Editor"
    },
    {
        name: "Phong Tran",
        position: "Copy Editor"
    },
    {
        name: "Nora Zolfaghari",
        position: "Copy Editor"
    },
    {
        name: "Sierra Loh",
        position: "Copy Editor"
    },
    {
        name: "Sharon Chan",
        position: "Copy Editor"
    },
    {
        name: "Sofia Moniz",
        position: "Copy Editor"
    },
    {
        name: "Sophia Erenberg",
        position: "Copy Editor"
    },
    {
        name: "Vyusti Magan Shroff",
        position: "Copy Editor"
    },
    {
        name: "Andrew Hong",
        position: "Web Developer"
    },
    {
        name: "Emily Shen",
        position: "Web Developer"
    },
    {
        name: "Charel Suarez",
        position: "Web Developer"
    }
];

const visualCredits: CreditedPerson[] = [
    {
        name: "Kaisa Kasekamp",
        position: "Creative Director"
    },
    {
        name: "Vicky Huang",
        position: "Illustration Editor"
    },
    {
        name: "Milena Pappalardo",
        position: "Video Editor"
    },
    {
        name: "Evelyn Bolton",
        position: "Illustrator"
    },
    {
        name: "Elena Osipyuan",
        position: "Illustrator"
    },
    {
        name: "Biew Biew Sakulwannadee",
        position: "Illustrator"
    },
    {
        name: "Jishna Sunkara",
        position: "Illustrator"
    },
    {
        name: "Zuhal Olomi",
        position: "Illustrator"
    },
    {
        name: "Nilima Paul",
        position: "Illustrator"
    },
    {
        name: "Arthur Denyson Hamdani",
        position: "Visuals Contributor"
    },
    {
        name: "Jennifer Su",
        position: "Visuals Contributor"
    },
    {
        name: "Studio X",
        position: "Visuals Contributor"
    },
    {
        name: "Doris McCarthy Gallery",
        position: "Visuals Contributor"
    }
];

export const creditsData: CreditsType = {
    article_credits: articleCredits,
    visual_credits: visualCredits
};