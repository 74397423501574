export interface VarsityLogoProps {
    className?: string;
}

export function VarsityLogo({ className }: VarsityLogoProps) {
    return (
        <svg aria-label="A stylized, black circle with a serif, lowercase V in the middle." xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="314" height="311" viewBox="0 0 314 311" xmlSpace="preserve" fill="currentColor" className={className}>
            <desc>Created with Fabric.js 3.6.3</desc>
            <defs>
            </defs>
            <g transform="matrix(1.78 0 0 1.78 156.88 155.33)"  >
                <path style={{ stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeDashoffset: 0, strokeLinejoin: 'miter', strokeMiterlimit: 4, fillRule: 'nonzero', opacity: 1 }} transform="translate(-385.96, -485.94)" d="M 364.77 401.63 c -31.2 8.28 -55.34 31.72 -63.63 62 c -16 57.46 31.55 113.87 92.36 109.11 c 32.79 -2.47 63.64 -25.21 75.44 -55.35 c 5.82 -15.33 6.7 -40.54 1.77 -56.58 C 457.31 416.78 409.19 389.82 364.77 401.63 Z m 0 51.64 c 0 0.88 -1 2.47 -2.46 3.7 a 4.42 4.42 0 0 0 -1.41 5.11 c 3.17 8.29 34.37 59.93 35.6 59.23 c 0.71 -0.53 7.58 -11.63 15 -24.85 c 13 -22.39 13.75 -24.15 12.17 -30.32 c -1.42 -5.64 -2.65 -6.88 -9 -9.17 c -4.23 -1.41 -7.58 -3.17 -7.58 -3.88 s 7.58 -1.41 16.74 -1.41 s 16.75 0.53 16.75 1.24 c 0 1.94 -53.24 92.18 -54.29 92.18 c -1.59 0 -53.23 -89 -53.23 -91.48 c 0 -1.23 5.81 -1.94 15.86 -1.94 C 357.72 451.68 364.77 452.39 364.77 453.27 Z" strokeLinecap="round" />
            </g>
        </svg>
    );
}